<template>

  <div :class="'fI mobile_' + isMobile()">

    <div id="loginWidget">
      <div class="pulsate loginWidgetText">
        <div v-if="errors && errors[0]">
          <div v-for="error in errors" :key="error" class="error-messages">
            <span class="red pulsate" v-for="(v, k) in error" :key="k">{{ v }}</span>
          </div>
        </div>
        <div v-else>~ <translate>CHOOSE 2FA MODE</translate> ~</div>
      </div>
    </div>

    <!--LoginWidgets /-->

    <div style="overflow: hidden;">

      <FarmgodHeader>
        <div class='row' style="top: 10%; position: sticky;">
          <div class='col-sm-12'>
            <h1 id="logo_main" class="teaserLogoMain">
              <Logo v-bind:link="'none'" v-bind:title="'Powered by FarmGod.io'"/>
            </h1>
          </div>
        </div>
      </FarmgodHeader>

      <div id='box-container' style="width: 100%; overflow: hidden;">

        <div id="OtpMethodForm">
          <div class="logo_spacer_top"></div>
          <div class="loginFormWrapper">
            <div class="formText" style="padding: 0px 0px 20px 0;margin: auto;" v-translate>
                For each login, you will be also required to <b>provide a one-time password</b>. Please select a channel you would like to use to receive this OTP.
            </div>
            <div class="formText" style="margin: auto;">
              <FormulateInput
                  :label="$gettext('OTP-Method')"
                  :tooltip="$gettext('Choose between OTP via App (recommended for maximum security) or E-Mail.')"
                  name='mode'
                  class="otpMode"
                  v-model.number='otp_method'
                  type='select'
                  :options="{ app: 'via mobile app (e.g. Authy)', email: 'via your registered email' }"
              />
            </div>
          </div>
          <div class="logo_spacer_bottom"></div>
          <div class="form-group">
            <router-link exact :to="{ name: 'register' }" v-if="otp_method && otp_method != ''">
              <button class="landingPageButton btn btn-secondary" @click.prevent="chooseMethod()"><translate>CONTINUE</translate><i class="fas fas-xsBtn fa-angle-double-right fas-right"></i></button>
            </router-link>
            <button v-else class="landingPageButton btn btn-primary trans" style="opacity: 0.3; cursor: not-allowed;"><translate>CONTINUE</translate><i class="fas fas-xsBtn fa-angle-double-right fas-right"></i></button>
          </div>
        </div>

      </div>

    </div>

    <FarmgodFooter>
      <LoggedOutFooter />
    </FarmgodFooter>

  </div>

</template>

<script>
import { mapState } from 'vuex'
import FarmgodHeader from '@/components/layout/TheHeader'
import Logo from '@/components/layout/Logo'

import FarmgodFooter from '@/components/layout/TheFooter'
import LoggedOutFooter from '@/components/layout/Footers/LoggedOut'

export default {
  name: 'TwoFaMethodSelector',
  components: { FarmgodHeader, Logo, FarmgodFooter, LoggedOutFooter },
  data () {
    return {
      email: (this.$route.query.email ? this.$route.query.email : null),
      otp_method: 'app'
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    chooseMethod () {
      if (this.otp_method === 'app') {
        this.$router.push({ name: '2fa_setup__otp_app', query: { email: this.email } })
      } else {
        this.$router.push({ name: '2fa_setup__otp_email' })
      }
    }
  }
}
</script>

<style>
  @import '../../assets/style/sessions.scss';
</style>
